import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import QuestionGenerator from '../../tools/QuestionGenerator'

const QuestionGeneratorPage = () => (
    <Layout>
        <SEO title="Random Question Generator – Generate Questions Randomly Online!" />
		<section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Random Question Generator</h1>
                    <h2 className="subtitle">
                        {/* Create a totally random password of up to 64 characters with your own choice of vocabulary (e.g. only letters, letters and symbols, etc). This online strong password generator also checks the password against a list of leaked passwrrd to ensurre it is safe to use. */}
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container is-fluid">
				<QuestionGenerator />
            </div>
        </section>

    </Layout>
)

export default QuestionGeneratorPage
