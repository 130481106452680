import React, { useState } from 'react'
import Chance from 'chance'
import Textarea from '../components/Textarea'

const QuestionGenerator = () => {

	const [count, setCount] = useState(3)
	const [questions, setQuestions] = useState([])

    const generate = e => {
		e.preventDefault()
		
		const chance = new Chance()
		let vocabulary = ``

		let pass = chance.string({
			pool: vocabulary,
			length: count
		})

        setPassword(pass)
    }

    return (
		<div className="columns is-desktop">
			<form className="column" onSubmit={generate}>

				<div className="field">
					<label className="label" htmlFor="count">
						How many questions?
					</label>
					<div className="control">
						<input
							className="input"
							type="number"
							id="count"
							min="1"
							max="100"
							placeholder="1"
							value={String(count)}
							onChange={e => setCount(parseInt(e.target.value))}
						/>
					</div>
				</div>

				<div>
					<button
						type="submit"
						className="button is-primary"
					>
						Generate
					</button>
				</div>
			
			</form>

			<div className="column is-four-fifths">
				<Textarea
					className="textarea"
					style={{
						width: `100%`,
						backgroundColor: `#fff`,
						padding: `1rem`,
						fontFamily: `monospace`,
					}}
					rows={questions.length}
					value={questions.join(`\n`)}
					showCopyToClipboard={true}
					readOnly={true}
				/>
			</div>
		</div>
    )
}

QuestionGenerator.propTypes = {}

QuestionGenerator.defaultProps = {}

export default QuestionGenerator
